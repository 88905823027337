export const environment = {
  baseUrl: 'https://folio.staging-euw3.aodocs.app/',
  apiUrl: 'api/',
  clientId: '537356912852-tnih9hs62fftu106tnaq5u0enncc5d74.apps.googleusercontent.com',
  aodocsUrl: 'https://staging-euw3.aodocs.app/',
  version: '4.2.0',
  production: false,
  firebase: {
    apiKey: 'AIzaSyD-C1fMD2nFMnr15gp7WPl26Urc_RRBUHs',
    authDomain: 'aodocs-core-staging-eu.firebaseapp.com',
    projectId: 'aodocs-core-staging-eu',
    storageBucket: 'aodocs-core-staging-eu.appspot.com',
    messagingSenderId: '824447885809',
    appId: '1:824447885809:web:e10a7e149702801791e450'
  }
};
